'use client'

import { getCurrentPosition } from 'lib/utils'
import { useCallback, useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import isObject from 'lodash/isObject'
import hash from 'object-hash'

export type CurrentPosition = undefined | false | GeolocationPosition
export type LocatePosition = () => ReturnType<typeof getCurrentPosition>
export interface UseLazyCurrentPositionResult {
  currentPosition: CurrentPosition,
  locate: LocatePosition,
  isPending: boolean,
}

/**
 * Wrapper arround `navigator.geolocation.getCurrentPosition`. It
 * will return:
 * - {@link GeolocationPosition} if geolocation was successfull
 * - `false` if not
 * - `undefined` if not done yet
 */
function useLazyCurrentPosition(options?: PositionOptions): UseLazyCurrentPositionResult {
  //
  // proxy data load through query
  const {
    mutateAsync: locateMutation,
    data: currentPositionData,
    isPending: isCurrentPositionPending,
  } = useMutation({
    mutationKey: ['getCurrentPosition', hash(options ?? {})],
    mutationFn: async (_payload: unknown) => getCurrentPosition(options),
    gcTime: 1000 * 60 * 1,
  })

  //
  // Export proxy
  const locate = useCallback(async () => locateMutation({}), [locateMutation])

  //
  // Current position
  const currentPosition = useMemo(
    () => isObject(currentPositionData) ? currentPositionData : undefined,
    [currentPositionData]
  )

  //
  // Return
  return useMemo(() => ({
    currentPosition,
    isPending: isCurrentPositionPending,
    locate,
  }), [currentPosition, isCurrentPositionPending, locate])
}

export default useLazyCurrentPosition
