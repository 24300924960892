import { useQueryError } from 'lib/hooks'
import { isPresent } from 'lib/utils'
import isFunction from 'lodash/isFunction'
import { useCallback, useMemo } from 'react'

import {
  ApolloQueryResult, FetchResult, OperationVariables, useMutation, useQuery,
} from '@apollo/client'
import { useSignedIn } from '@vayapin/oauth-kit-react'

import mutationCreate from './mutationCreate'
import mutationRemove from './mutationRemove'
import mutationUpdate from './mutationUpdate'
import queryId from './queryId'
import queryVayapin from './queryVayapin'

import type {
  BookmarkCreateInput, BookmarkDeleteInput, BookmarkUpdateInput, Mutation,
  Query, User_Bookmark, User_BookmarkAttributes
} from '@vayapin/cs-types-my'

export type MutationResult = FetchResult<
  Mutation, Record<string, unknown>, Record<string, unknown>
> | false

export interface UseVayaPinBookmarkResult {
  bookmark?: User_Bookmark;
  loading: boolean;
  mutationLoading: boolean;
  createBookmark: (
    attributes: User_BookmarkAttributes
  ) => Promise<MutationResult>;
  updateBookmark: (
    id: string, attributes: User_BookmarkAttributes
  ) => Promise<MutationResult>;
  deleteBookmark: (
    id: string
  ) => Promise<MutationResult>;
  refetchBookmark: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<Query>>
}

function useVayaPinBookmark(
  id?: string,
  vayapin?: string,
  onCreate?: () => void,
  onUpdate?: () => void,
  onDelete?: () => void,
): UseVayaPinBookmarkResult {
  const signedIn = useSignedIn()
  const query = useMemo(() => isPresent(id) ? queryId : queryVayapin, [id])
  const fetchVariables = useMemo(() => {
    if (isPresent(id)) return { id }
    if (isPresent(vayapin)) return { vayapin }
    return undefined
  }, [id, vayapin])

  //
  // Query data
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useQuery<Query>(query, {
    context: { api: 'my' },
    variables: fetchVariables,
    skip: !signedIn || !fetchVariables,
  })

  //
  // Mutation data
  const [create, {
    loading: createLoading,
    error: createError,
  }] = useMutation<Mutation, BookmarkCreateInput>(mutationCreate, {
    context: { api: 'my' },
  })

  //
  // Mutation data
  const [update, {
    loading: updateLoading,
    error: updateError,
  }] = useMutation<Mutation, BookmarkUpdateInput>(mutationUpdate, {
    context: { api: 'my' },
  })

  //
  // Mutation data
  const [remove, {
    loading: removeLoading,
    error: removeError,
  }] = useMutation<Mutation, BookmarkDeleteInput>(mutationRemove, {
    context: { api: 'my' },
  })

  //
  // Error handling
  useQueryError(queryError)
  useQueryError(createError)
  useQueryError(updateError)
  useQueryError(removeError)

  //
  // Bookmark data
  const bookmark: User_Bookmark | undefined = useMemo(() => {
    const user = queryData?.currentUser

    return (user?.bookmark || user?.bookmarkByVayapin) as User_Bookmark
  }, [queryData?.currentUser])

  //
  // Create
  const createBookmark = useCallback(async (
    attributes: User_BookmarkAttributes
  ) => {
    if (!signedIn) return false

    const result = await create({ variables: { attributes } })
    if (isFunction(onCreate)) onCreate()
    return result
  }, [create, onCreate, signedIn])

  //
  // Update
  const updateBookmark = useCallback(async (
    bookmarkId: string,
    attributes: User_BookmarkAttributes
  ) => {
    if (!signedIn) return false

    const result = await update({ variables: {
      id: bookmarkId, attributes
    } })
    if (isFunction(onUpdate)) onUpdate()
    return result
  }, [onUpdate, signedIn, update])

  //
  // Delete
  const deleteBookmark = useCallback(async (
    bookmarkId: string,
  ) => {
    if (!signedIn) return false

    const result = await remove({ variables: { id: bookmarkId } })
    if (isFunction(onDelete)) onDelete()
    return result
  }, [onDelete, remove, signedIn])

  //
  // return value
  return useMemo(() => ({
    bookmark,
    loading: queryLoading || createLoading || updateLoading || removeLoading,
    mutationLoading: createLoading || updateLoading || removeLoading,
    createBookmark,
    updateBookmark,
    deleteBookmark,
    refetchBookmark: refetch,
  }), [
    bookmark,
    queryLoading,
    createLoading,
    updateLoading,
    removeLoading,
    createBookmark,
    updateBookmark,
    deleteBookmark,
    refetch,
  ])
}

export default useVayaPinBookmark
