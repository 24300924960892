import { useQueryError } from 'lib/hooks'
import { isPresent, isValidNumber } from 'lib/utils'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import some from 'lodash/some'
import {
  ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react'

import { useLazyQuery } from '@apollo/client'

import Styles from './GeocodeSearch.module.sass'
import query from './query'
import SearchInput from './SearchInput'

import type { GeocoderResult, Query } from '@vayapin/cs-types-my'

export interface LatLngLiteral {
  lat: number;
  lng: number;
}

export interface GeocodeSearchProps {
  /**
   * ISO-3166-1 country scope to limit
   * search results
   */
  countryScope?: string;
  onSelect?: (value: LatLngLiteral) => void;
}

const GeocodeSearch: FC<GeocodeSearchProps> = ({
  countryScope,
  onSelect,
}) => {
  const [search, setSearch] = useState('')
  const [suggestions, setSuggestions] = useState<GeocoderResult[]>([])
  const suggestionsLoading = useRef(true)
  const hasSuggestions = useMemo(() => some(suggestions), [suggestions])

  //
  // Query: geolocation
  const [fetchGeocodeResults, {
    data: queryGeocodeData,
    loading: mapSearchLoading,
    error: queryGeocodeError,
  }] = useLazyQuery<Query>(query)

  //
  // Handle errors
  useQueryError(queryGeocodeError)

  //
  // Debounced suggestions loading
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchGeocodeResults = useCallback(debounce(
    (searchString?: string) => {
      if (!suggestionsLoading.current) return

      if (!isPresent(searchString)) {
        setSuggestions([])
        return
      }

      void fetchGeocodeResults({ variables: {
        search: searchString,
        region: countryScope,
      } })
    },
    350,
  ), [countryScope])

  //
  // Debounced fetch if the search value changes
  useEffect(
    () => debouncedFetchGeocodeResults(search),
    [debouncedFetchGeocodeResults, search]
  )

  //
  // Set suggestions
  useEffect(() => {
    const results = queryGeocodeData?.geocodeResults?.nodes

    setSuggestions(results && some(results) ? results : [])
  }, [queryGeocodeData?.geocodeResults?.nodes])


  //
  // Click on selected item
  const onClickItem = useCallback((value: GeocoderResult) => {
    if (!isValidNumber(value?.latitude)) return
    if (!isValidNumber(value?.longitude)) return

    suggestionsLoading.current = false

    setSearch((s) => value?.address || s)

    setSuggestions([])

    if (isFunction(onSelect)) {
      onSelect({
        lat: value?.latitude,
        lng: value?.longitude,
      })
    }
  }, [onSelect])

  //
  // Handle input change
  const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    suggestionsLoading.current = true
    setSearch(e?.target?.value)
  }, [])

  return (
    <div className={Styles.wrapper}>
      <SearchInput
        loading={mapSearchLoading}
        value={search}
        onChange={onInputChange}
      />
      {hasSuggestions && (
        <ul className={Styles.suggestions}>
          {suggestions.map((s) => (
            <li key={s.id}>
              <button
                type="button"
                onClick={() => onClickItem(s)}
              >
                {s.address}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default GeocodeSearch
