'use client'

import type { CountrySelectorProps, ExtendedCountry } from './Types'

import { FC } from 'react'
import Select, { GroupBase, OptionProps, SingleValueProps } from 'react-select'
import { useTranslation } from 'i18n/client'
import CountryOptionContent from './CountryOptionContent'
import Styles from './CountrySelector.module.sass'
import useData from './useData'

const CountrySingleValue: FC<
  SingleValueProps<ExtendedCountry, false, GroupBase<ExtendedCountry>>
> = ({
  data,
  selectProps
}) => {
  // @ts-ignore
  const { explore } = selectProps

  return (
    <CountryOptionContent data={data} explore={explore} asInput />
  )
}

const CountryOption: FC<
  OptionProps<ExtendedCountry, false, GroupBase<ExtendedCountry>>
> = ({
  data,
  setValue,
  isFocused,
}) => (
  <button
    onClick={() => setValue(data, 'select-option')}
    className={Styles.optionButton}
  >
    <CountryOptionContent data={data} selected={isFocused} />
  </button>
)

const CountrySelector: FC<CountrySelectorProps> = (props) => {
  const { t } = useTranslation()

  const {
    loading,
    countries,
    currentValue,
    filterOption,
    onChange,
  } = useData(props)

  return (
    <Select
      {...props}
      inputId={props?.id}
      isLoading={loading}
      isSearchable
      name="color"
      value={currentValue}
      options={countries}
      placeholder={t('pin.search.selectCountryHint', 'Select country')}
      menuShouldScrollIntoView={false}
      menuPosition='fixed'
      filterOption={filterOption}
      onChange={onChange}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      components={{
        Option: CountryOption,
        SingleValue: CountrySingleValue,
      }}
    />
  )
}

export default CountrySelector
