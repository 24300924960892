import LogoSVG from 'assets/images/2021_02_22_logo_green_white.svg'
import classnames from 'classnames'
import { useTranslation } from 'i18n/client'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { FC, ReactNode, type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { FaGlobeAmericas, FaHeart } from 'react-icons/fa'
import {
  MdAccountCircle,
  MdLocationPin, MdLogout, MdOutlineExplore,
  MdSchool,
  MdSettings
} from 'react-icons/md'
import { SlDirections } from 'react-icons/sl'

import OAuthKit from '@vayapin/oauth-kit'
import { useAuthHelper, useSignedIn } from '@vayapin/oauth-kit-react'

import Styles from './NavigationContent.module.sass'

interface NavigationItem {
  key: string;
  type?: 'item' | 'separator';
  i18nKey?: string;
  i18nFallback?: string;
  href?: string;
  icon?: ReactNode;
}

const ITEMS: NavigationItem[] = [
  {
    key: 'search',
    i18nKey: 'common.search',
    i18nFallback: 'Search',
    href: '/',
    icon: <BsSearch />,
  },
  {
    key: 'explore',
    i18nKey: 'common.discover',
    i18nFallback: 'Discover',
    href: '/explore',
    icon: <MdOutlineExplore />,
  },
  {
    key: 'guides',
    i18nKey: 'common.guides',
    i18nFallback: 'Guides',
    href: '/guides',
    icon: <SlDirections />,
  },
  {
    key: 'favourites',
    i18nKey: 'common.favourites',
    i18nFallback: 'Favourites',
    href: '/favourites',
    icon: <FaHeart />,
  },
  {
    key: 'tutorial',
    i18nKey: 'common.tutorial',
    i18nFallback: 'Tutorial',
    href: '/tutorial',
    icon: <MdSchool />,
  },
]

const ITEMS_AUTH: NavigationItem[] = [
  {
    key: 'account',
    i18nKey: 'common.myPins',
    i18nFallback: 'MyPins',
    href: '/account',
    icon: <MdLocationPin />,
  },
  {
    key: 'settings',
    i18nKey: 'settings.heading',
    i18nFallback: 'Settings',
    href: '/account/settings',
    icon: <MdSettings />,
  },
  {
    type: 'separator',
    key: 'separator',
  },
]

export interface NavigationContentProps {
  drawer?: boolean;
}

const NavigationContent: FC<NavigationContentProps> = ({
  drawer = false,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { getSignOutUri } = useAuthHelper()
  const signedIn = useSignedIn()
  const [signOutUrl, setSignOutUrl] = useState<string | undefined>(undefined)

  const liClasses = useMemo(() => classnames({
    [Styles.li]: !drawer,
    [Styles.liDrawer]: drawer,
  }), [drawer])

  const containerClasses = useMemo(() => classnames({
    [Styles.container]: true,
    [Styles.containerDrawer]: drawer,
  }), [drawer])

  const items = useMemo<NavigationItem[]>(() => {
    const i = [...ITEMS]

    if (drawer) {
      i.push({
        key: 'more',
        i18nKey: 'common.ourWeb',
        i18nFallback: 'Our Web',
        href: 'https://www.vayapin.com',
        icon: <FaGlobeAmericas />,
      })
    }

    if (signedIn) {
      i.push(...ITEMS_AUTH)

      if (signOutUrl) {
        i.push({
          key: 'signOut',
          i18nKey: 'common.signOut',
          i18nFallback: 'Sign Out',
          href: signOutUrl,
          icon: <MdLogout />
        })
      }
    } else if (drawer) {
      i.push({
        key: 'signin',
        i18nKey: 'common.signin',
        i18nFallback: 'Sign In',
        href: '/account',
        icon: <MdAccountCircle />,
      })
    }

    return i
  }, [signedIn, signOutUrl, drawer])

  useEffect(() => {
    const load = async () => { setSignOutUrl(await getSignOutUri()) }
    void load()
  }, [getSignOutUri])

  const onSignOut = useCallback((
    e: MouseEvent<HTMLAnchorElement, MouseEvent<Element, MouseEvent>> | undefined,
    href: string
  ) => {
    e?.preventDefault()
    void OAuthKit.localSignOut()
    void router.push(href)
  }, [router])

  return (
    <div className={containerClasses}>
      {drawer && (
        <Link href="/" className={Styles.logo}>
          <Image
            src={LogoSVG}
            alt="VayaPin"
            width={150}
          />
        </Link>
      )}
      <ul>
        {items.map((item) => item.type === 'separator' ? (
          <li key={item.key} className={`${Styles.li} separator`}></li>
        ) : (
          <li key={item.key} className={liClasses}>
            {
              item.key === 'signOut' ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                // @ts-ignore
                <Link onClick={(e) => onSignOut(e, item.href || '')} href=''>
                  <>
                    {drawer && (
                      <>
                        {item.icon}&nbsp;
                      </>
                    )}
                    <span>
                      {item.i18nKey && t(item.i18nKey, item.i18nFallback || '')}
                    </span>
                    {!drawer && (
                      <>
                        &nbsp;{item.icon}
                      </>
                    )}
                  </>
                </Link>
              ) : (
                <Link href={item.href || ''}>
                  <>
                    {drawer && (
                      <>
                        {item.icon}&nbsp;
                      </>
                    )}
                    <span>
                      {item.i18nKey && t(item.i18nKey, item.i18nFallback || '')}
                    </span>
                    {!drawer && (
                      <>
                        &nbsp;{item.icon}
                      </>
                    )}
                  </>
                </Link>
              )
            }
          </li>
        ))}
      </ul>
    </div>
  )
}

export default NavigationContent
