import first from 'lodash/first'
import { useMemo } from 'react'

import useReverseGeocode from './useReverseGeocode'

function useCurrentCountry(skip = false) {
  const {
    loading,
    results,
  } = useReverseGeocode(skip)

  return useMemo(() => {
    const result = first(results || [])

    return {
      iso31661: result?.countryCode,
      loading,
    }
  }, [loading, results])
}

export default useCurrentCountry
