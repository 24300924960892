'use client'

import {
  FC,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { usePathname } from 'next/navigation'
import Drawer from 'react-modern-drawer'
import {
  DEFAULT_DATA,
  INavigationContext,
  Provider
} from './NavigationContext'
import { NavigationContent } from 'organisms'
import Styles from './NavigationDrawerProvider.module.sass'

interface NavigationDrawerProviderProps {
  children?: ReactNode | null,
}

const NavigationDrawerProvider: FC<NavigationDrawerProviderProps> = ({
  children,
}) => {
  const [drawerOpen, setVDrawerOpen] = useState(false)
  const pathname = usePathname()

  const setDrawerOpen = useCallback((value: boolean) => {
    setVDrawerOpen(value)
    document?.querySelector('body')?.classList.add('drawerOpen')
  }, [])

  const toggleDrawerOpen = useCallback(() => {
    setVDrawerOpen(!drawerOpen)
    document?.querySelector('body')?.classList.remove('drawerOpen')
  }, [drawerOpen])

  const contextData = useMemo<INavigationContext>(() => ({
    ...DEFAULT_DATA,
    drawerOpen,
    setDrawerOpen,
    toggleDrawerOpen,
  }), [drawerOpen, setDrawerOpen, toggleDrawerOpen])

  useEffect(() => {
    setDrawerOpen(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <Provider value={contextData}>
      {children}
      <Drawer
        duration={200}
        open={drawerOpen}
        direction="left"
        onClose={toggleDrawerOpen}
        className={Styles.drawer}
        customIdSuffix="vpdrawer"
      >
        <NavigationContent drawer />
      </Drawer>
    </Provider>
  )
}

export default NavigationDrawerProvider
