import { getUUID } from 'lib/utils'
import { useMemo } from 'react'

function useUUID() {
  return useMemo(
    () => getUUID(),
    []
  )
}

export default useUUID
