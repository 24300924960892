import { gql } from '@apollo/client'

const queryId = gql`
  query bookmark($id :ID!) {
    currentUser {
      bookmark(id: $id){
        id,
        title
      }
    }
  }
`

export default queryId
