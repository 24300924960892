'use client'

import { FC, ReactNode, useCallback, useMemo } from 'react'
import Toast, { Toaster, ToastOptions as RHToastOptions } from 'react-hot-toast'

import { DEFAULT_DATA, Provider } from './Context'

import type { ToastOptions } from './Types'

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const toast = useCallback((options: ToastOptions) => {
    const hotToastOptions: RHToastOptions = { ...(options?.hotToastOptions || {}) }
    const content = options?.content || ''

    if (options?.id) hotToastOptions.id = options.id

    switch (options?.type) {
      case 'success':
        return Toast.success(content, hotToastOptions)
      case 'error':
        return Toast.error(content, hotToastOptions)
      case 'loading':
        return Toast.loading(content, hotToastOptions)
      case 'custom':
        return Toast.custom(content, hotToastOptions)
      default:
        return Toast(content, hotToastOptions)
    }
  }, [])

  const contextData = useMemo(() => ({
    ...DEFAULT_DATA,
    toast,
  }), [toast])

  return (
    <Provider value={contextData}>
      {children}
      <Toaster />
    </Provider>
  )
}

export default ToastProvider
