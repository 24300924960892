import isPresent from '@vayapin/utils/isPresent'

export const URL_PROTOCOLS = ['http', 'https', 'ftp']

function isURL(
  url: unknown,
  allowedProtocols: string[] | undefined = URL_PROTOCOLS
): boolean {
  if (!isPresent(url)) return false
  if (url.indexOf('mailto:') === 0) return false

  let split, protocol

  split = url.split('#')
  url = split.shift()
  split = (`${url as string}`).split('?')
  url = split.shift()
  split = (`${url as string}`).split('://')

  if (split.length > 1) {
    protocol = split.shift()?.toLowerCase()
    url = split.shift()

    if (allowedProtocols.indexOf(`${protocol as string}`) === -1) {
      return false
    }
  } else if ((`${url as string}`).slice(0, 2) !== '//') {
    return false
  }

  if (!isPresent(url)) return false

  split = url.split('/')
  url = split.shift()

  if (!isPresent(url)) return false

  return true
}

export default isURL
