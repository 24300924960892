import { gql } from '@apollo/client'

const query = gql`
  query geocodeResults($search: String!, $region: String) {
    geocodeResults(search: $search, region: $region) {
      nodes {
        latitude
        longitude
        address
        countryCode
      }
    }
  }
`

export default query
