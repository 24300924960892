import { LoadingIndicator } from 'atoms'
import { ChangeEventHandler, FC } from 'react'

import Styles from './SearchInput.module.sass'
import { useTranslation } from 'i18n/client'

interface SearchInputProps {
  loading?: boolean;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: FC<SearchInputProps> = ({
  loading,
  value,
  onChange,
}) => {
  const { t } = useTranslation()
  return (
    <div className={Styles.wrapper}>
      <input
        value={value}
        onChange={onChange}
        placeholder={t('pin.create.pinLocation.searchLocation', 'Search location') || ''}
      />
      {loading && (
        <div className={Styles.spinner}>
          <LoadingIndicator dark />
        </div>
      )}
    </div>
  )
}
export default SearchInput
