import { gql } from '@apollo/client'

const mutationCreate = gql`
  mutation createBookmark(
    $attributes: User_BookmarkAttributes!
  ) {
    currentUserBookmarkCreate(input: {
      attributes: $attributes
    }) {
      errors
      success
    }
  }
`

export default mutationCreate
