import { Flag } from 'atoms'
import classNames from 'classnames'
import { FC, useMemo } from 'react'

import Styles from './CountryOptionContent.module.sass'

import type { ExtendedCountry } from './Types'

interface CountryOptionContentProps {
  asInput?: boolean;
  selected?: boolean;
  data: ExtendedCountry;
  explore?: boolean;
}

const CountryOptionContent: FC<CountryOptionContentProps> = ({
  asInput = false,
  selected = false,
  data: {
    iso31661,
    localName,
    name
  },
  explore,
}) => {
  const cls = useMemo(() => classNames({
    [Styles.container]: true,
    [Styles.containerAsInput]: asInput,
    [Styles.containerSelected]: selected,
  }), [asInput, selected])

  return (
    <div className={cls}>
      <Flag country={iso31661 as string} width={asInput && explore ? 35 : 20} />
      <div className={Styles.titleContainer}>
        <p className={Styles.title}>
          {asInput && explore ? 'Exploring' : null} {localName || name}
        </p>
        {
          asInput && explore ? (
            <p className={Styles.subtitle}>Press to select another country</p>
          ) : null
        }
      </div>
    </div>
  )
}

export default CountryOptionContent
