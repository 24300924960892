import { gql } from '@apollo/client'

const mutationRemove = gql`
  mutation removeBookmark(
    $id: ID!
  ) {
    currentUserBookmarkDelete(input: {
      id: $id
    }) {
      errors
      success
    }
  }
`

export default mutationRemove
