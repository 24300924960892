class ConfigError extends Error {

  constructor(...args) {
    super(...args)

    if (Error.captureStackTrace) Error.captureStackTrace(this, ConfigError)
  }

}

export default ConfigError
