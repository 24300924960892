import isPresent from '@vayapin/utils/isPresent'
import compact from 'lodash/compact'

// eslint-disable-next-line max-len
const DOMAIN_REGEX = /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/

/**
 * Returns the domain including TLD of a passed url. Subdomains will
 * not be part of the result. Path and additional information will be
 * removed too. If the URL is not valid, undefined will be returned.
 */
function urlExtractDomainHostname(url?: string): string | undefined {
  if (!isPresent(url)) return undefined

  try {
    const urlObj = new URL(url)
    const hostname = urlObj.hostname.match(DOMAIN_REGEX) || []
    const result = compact([hostname[1], hostname[3]])

    return result.length === 2 ? result.join('') : undefined
  } catch (e) {
    // If `new URL` throws it is not a valid URL
    // so we return undefined.
    return undefined
  }
}

export default urlExtractDomainHostname
