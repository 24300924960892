import { gql } from '@apollo/client'

const queryVayapin = gql`
  query bookmark($vayapin :String!) {
    currentUser {
      bookmarkByVayapin(vayapin: $vayapin){
        id,
        title
      }
    }
  }
`

export default queryVayapin
