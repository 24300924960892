import { useCurrentPosition, useQueryError } from 'lib/hooks'
import { isValidNumber } from 'lib/utils'
import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import query from './query'

import type { Query } from '@vayapin/cs-types-public'

function useReverseGeocode(skip = false) {
  const position = useCurrentPosition(skip)

  //
  // Extract location
  const location = useMemo(() => {
    if (!position) return undefined

    if (!isValidNumber(position?.coords?.latitude)) return undefined
    if (!isValidNumber(position?.coords?.longitude)) return undefined

    return {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
  }, [position])

  //
  // Query data
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<Query>(
    query,
    {
      skip: !location,
      variables: { ...location }
    }
  )

  //
  // Handle errors
  useQueryError(queryError)

  //
  //
  return useMemo(() => ({
    loading: queryLoading,
    results: queryData?.geocodeReverseResults?.nodes || [],
  }), [queryData?.geocodeReverseResults?.nodes, queryLoading])
}

export default useReverseGeocode
