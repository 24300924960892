/**
 * @deprecated Use ClientConfig/ServerConfig instead
 */

import isString from 'lodash/isString'
import ConfigError from '~/util/errors/ConfigError'
import ClientConfig from 'lib/config/ClientConfig'

export const NEXT_PUBLIC_SHARED_COOKIE_HOST = ClientConfig.COOKIE_SHARED_DOMAIN
export const NEXT_PUBLIC_STAGING = ClientConfig.ENVIRONMENT === 'staging'
export const NEXT_PUBLIC_PUBLIC_URL = ClientConfig.PUBLIC_URL
export const NEXT_PUBLIC_ID_URL = ClientConfig.ID_URL
export const NEXT_PUBLIC_ID_OAUTH_ID = ClientConfig.ID_OAUTH_ID
export const NEXT_PUBLIC_PIN_SHARING_URL = ClientConfig.PIN_SHARING_URL
export const NEXT_PUBLIC_APP_PROTOCOL = ClientConfig.APP_PROTOCOL
export const NEXT_PUBLIC_TWITTER_SITE = ClientConfig.TWITTER_SITE
export const NEXT_PUBLIC_FB_APP_ID = ClientConfig.FB_APP_ID
export const NEXT_PUBLIC_COOKIE_DOMAIN = ClientConfig.COOKIE_FUNCTIONAL_DOMAIN
export const NEXT_PUBLIC_COOKIE_SECURE = ClientConfig.COOKIE_SECURE

/**
 * @deprecated Use ClientConfig.ENVIRONMENT instead
 */
export const NODE_ENV = ClientConfig.NODE_ENV

/**
 * @deprecated Use ServerConfig.CORE_SERVICE_SERVER_HOST instead. API calls to the core service server
 * should not be done from the browser. Instead create an API route here in next.
 */
export const NEXT_PUBLIC_CS_API_URL = ClientConfig.CS_API_URL

/**
 * @deprecated Use ClientConfig/ServerConfig instead
 */
export interface IConfigValues {
  NEXT_PUBLIC_SHARED_COOKIE_HOST?: string;
  NEXT_PUBLIC_STAGING: boolean;
  NEXT_PUBLIC_PUBLIC_URL?: string;
  NEXT_PUBLIC_ID_URL?: string;
  NEXT_PUBLIC_ID_OAUTH_ID?: string;
  NEXT_PUBLIC_PIN_SHARING_URL?: string;
  NEXT_PUBLIC_APP_PROTOCOL?: string;
  NEXT_PUBLIC_TWITTER_SITE?: string;
  NEXT_PUBLIC_FB_APP_ID?: string;
  NEXT_PUBLIC_COOKIE_DOMAIN?: string;
  NEXT_PUBLIC_COOKIE_SECURE?: boolean;

  /**
   * @deprecated Use ClientConfig.ENVIRONMENT instead
   */
  NODE_ENV?: string;

  /**
   * @deprecated Use ServerConfig.CORE_SERVICE_SERVER_HOST instead. API calls to the core service server
   * should not be done from the browser. Instead create an API route here in next.
   */
  NEXT_PUBLIC_CS_API_URL?: string;
}

/**
 * @deprecated Use ClientConfig/ServerConfig instead
 */
const ConfigValues: IConfigValues = {
  NEXT_PUBLIC_SHARED_COOKIE_HOST,
  NEXT_PUBLIC_STAGING,
  NEXT_PUBLIC_PUBLIC_URL,
  NEXT_PUBLIC_ID_URL,
  NEXT_PUBLIC_ID_OAUTH_ID,
  NEXT_PUBLIC_PIN_SHARING_URL,
  NEXT_PUBLIC_APP_PROTOCOL,
  NEXT_PUBLIC_TWITTER_SITE,
  NEXT_PUBLIC_FB_APP_ID,
  NEXT_PUBLIC_COOKIE_DOMAIN,
  NEXT_PUBLIC_COOKIE_SECURE,

  /**
   * @deprecated Use ClientConfig.ENVIRONMENT instead
   */
  NODE_ENV,

  /**
   * @deprecated Use ServerConfig.CORE_SERVICE_SERVER_HOST instead. API calls to the core service server
   * should not be done from the browser. Instead create an API route here in next.
   */
  NEXT_PUBLIC_CS_API_URL,
}

/**
 * @deprecated Use ClientConfig/ServerConfig instead
 */
const Config = {
  ...ConfigValues,

  /**
   * Checks if all values are available. If sth. is missing or
   * invalid, a ConfigError will be thrown.
   */
  validateEnvironment(): void {
    if (!isStringPresent(NEXT_PUBLIC_PUBLIC_URL)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_PUBLIC_URL missing')
    }

    if (!isStringPresent(NEXT_PUBLIC_ID_URL)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_ID_URL missing')
    }

    if (!isStringPresent(NEXT_PUBLIC_ID_OAUTH_ID)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_ID_OAUTH_ID missing')
    }

    if (!isStringPresent(NEXT_PUBLIC_PIN_SHARING_URL)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_PIN_SHARING_URL missing')
    }

    if (!isStringPresent(NEXT_PUBLIC_SHARED_COOKIE_HOST)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_SHARED_COOKIE_HOST missing')
    }

    if (!isStringPresent(NEXT_PUBLIC_COOKIE_DOMAIN)) {
      throw new ConfigError('process.env.NEXT_PUBLIC_COOKIE_DOMAIN missing')
    }
  },

  /**
   * @deprecated
   */
  reset() { /* legacy */ },

  /**
   * @deprecated
   */
  setFromEnvironment() { /* legacy */ },

  /**
   * @deprecated
   */
  loadToSelf() { /* legacy */ },

  /**
   * @deprecated
   */
  set(_key: string, _value: string) { /* legacy */ },

  /**
   * Get the config values.
   *
   * @deprecated Use e.g. `Config.NEXT_PUBLIC_ID_URL` directly
   */
  get() {
    return ConfigValues
  },

  /**
   * Get the config values.
   *
   * @deprecated Use e.g. `Config.NEXT_PUBLIC_ID_URL` directly
   */
  getFull() {
    return ConfigValues
  },
}

function isStringPresent(str: unknown) {
  return isString(str)
    && str.length > 0
    && str !== 'null'
    && str !== 'undefined'
}

export default Config
