import ClientConfig from 'lib/config/ClientConfig'

const env = {
  ENV_RELEASE: ClientConfig.RELEASE,
  ENV_STAGING: ClientConfig.ENVIRONMENT === 'staging',
  ENV_PIN_SHARING_URL: ClientConfig.PIN_SHARING_URL,
  ENV_ID_URL: ClientConfig.ID_URL,
  ENV_ID_API_URL: ClientConfig.ID_API_URL,
  ENV_CS_API_URL: ClientConfig.CS_API_URL,
  ENV_SHARED_COOKIE_HOST: ClientConfig.COOKIE_SHARED_DOMAIN,
  ENV_APP_PROTOCOL: ClientConfig.APP_PROTOCOL,
  ENV_TWITTER_SITE: ClientConfig.TWITTER_SITE,
  ENV_FB_APP_ID: ClientConfig.FB_APP_ID,
  ENV_ID_OAUTH_ID: ClientConfig.ID_OAUTH_ID,
  ENV_MAP_TILER_KEY: ClientConfig.MAP_TILER_KEY,
}

export const ENV_RELEASE = env.ENV_RELEASE
export const ENV_STAGING = env.ENV_STAGING
export const ENV_PIN_SHARING_URL = env.ENV_PIN_SHARING_URL
export const ENV_ID_URL = env.ENV_ID_URL
export const ENV_ID_API_URL = env.ENV_ID_API_URL
export const ENV_CS_API_URL = env.ENV_CS_API_URL
export const ENV_SHARED_COOKIE_HOST = env.ENV_SHARED_COOKIE_HOST
export const ENV_APP_PROTOCOL = env.ENV_APP_PROTOCOL
export const ENV_TWITTER_SITE = env.ENV_TWITTER_SITE
export const ENV_FB_APP_ID = env.ENV_FB_APP_ID
export const ENV_ID_OAUTH_ID = env.ENV_ID_OAUTH_ID
export const ENV_MAP_TILER_KEY = env.ENV_MAP_TILER_KEY

// default export
export default env
