import { createContext } from 'react'

export interface INavigationContext {
  drawerOpen: boolean,
  setDrawerOpen: (value: boolean) => void,
  toggleDrawerOpen: () => void,
}

export const DEFAULT_DATA: INavigationContext = {
  drawerOpen: false,
  setDrawerOpen(_value) { return undefined },
  toggleDrawerOpen() { return undefined },
}

const NavigationContext = createContext(DEFAULT_DATA)
NavigationContext.displayName = 'NavigationContext'

export const Provider = NavigationContext.Provider
export const Consumer = NavigationContext.Consumer

export default NavigationContext
