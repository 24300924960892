import { URL_PROTOCOL_REGEX } from './Constants'

/**
 * Prefixes a passed string with https:// (or the passed {@link fallback})
 * protocol, if {@link value} does not have any protocol.
 */
function ensureUrlProtocol(value: string, fallback = 'https'): string {
  if (!value) return `${fallback}://`
  if (!URL_PROTOCOL_REGEX.test(value)) return `${fallback}://${value}`

  return value
}

export default ensureUrlProtocol
