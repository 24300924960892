import { useTranslation } from 'i18n/client'
import { useQueryError } from 'lib/hooks'
import compact from 'lodash/compact'
import find from 'lodash/find'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  GroupBase, OptionsOrGroups, SingleValue,
  createFilter,
} from 'react-select'

import { useQuery } from '@apollo/client'

import query from './query'
import queryPublicPinCountries from './queryPublicPinCountries'

import type { Query } from '@vayapin/cs-types-public'
import type {
  CountrySelectorProps, ExtendedCountry, UseDataResult
} from './Types'

function useData({
  value,
  onClickItem,
  explore,
}: CountrySelectorProps): UseDataResult {
  const { t } = useTranslation('countries')
  const [currentValue, setCurrentValue] = useState<undefined | ExtendedCountry>(undefined)

  //
  // Query data
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useQuery<Query>(
    explore ? queryPublicPinCountries : query,
    { variables: { per: 500, page: 1 } }
  )

  //
  // Handle errors
  useQueryError(queryError)

  //
  // Prepare data
  const countries = useMemo<
    OptionsOrGroups<ExtendedCountry, GroupBase<ExtendedCountry>> | undefined
  >(() => {
    const countries = explore ? queryData?.publicPinCountries?.nodes : queryData?.countries?.nodes

    if (!countries) return undefined

    const nodes = map(countries, (ctr) => ({
      ...ctr,
      value: ctr?.iso31661 || '',
      localName: t(`countries.${ctr?.iso31661?.toUpperCase() || ''}`, ctr?.name || '') || '',
      label: t(`countries.${ctr?.iso31661?.toUpperCase() || ''}`, ctr?.name || ''),
    }))

    return nodes.sort((a, b) => a?.name?.localeCompare(b?.name || '') || 0)
  }, [explore, queryData?.countries?.nodes, queryData?.publicPinCountries?.nodes, t])

  //
  // Set value
  useEffect(() => {
    if (!countries || countries.length <= 0) {
      setCurrentValue(undefined)
      return
    }

    setCurrentValue(find<ExtendedCountry>(
      (countries as ExtendedCountry[]) || [],
      (ctr) => ctr?.iso31661 === value
    ))
  }, [countries, value, onClickItem])

  //
  // Filter
  const filterOption = useMemo(() => createFilter<ExtendedCountry>({
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    trim: true,
    stringify: (option) => compact([
      option?.data?.iso31661,
      option?.data?.localName,
      option?.data?.name
    ]).join(' '),
  }), [])

  //
  // On Select change
  const onChange = useCallback((country: SingleValue<ExtendedCountry>) => {
    setCurrentValue(country as ExtendedCountry)
    if (isFunction(onClickItem)) onClickItem(country?.iso31661 || undefined)
  }, [onClickItem])

  //
  // Data
  return useMemo(() => ({
    loading: queryLoading,
    countries,
    currentValue,
    filterOption,
    onChange,
  }), [
    queryLoading,
    countries,
    currentValue,
    filterOption,
    onChange,
  ])
}

export default useData
