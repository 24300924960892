import logError from './logError'

/**
 * Wrapper function arround `navigator.geolocation.getCurrentPosition`
 */
async function getCurrentPosition(options?: PositionOptions): Promise<
  GeolocationPosition | false
> {
  return new Promise((resolve, _reject) => {
    navigator.geolocation.getCurrentPosition(
      (pos: GeolocationPosition) => resolve(pos),
      (e) => {
        logError(e)
        resolve(false)
      },
      options,
    )
  })
}

export default getCurrentPosition
