import { gql } from '@apollo/client'

const mutationUpdate = gql`
  mutation updateBookmark(
    $id: ID!,
    $attributes: User_BookmarkAttributes!
  ) {
    currentUserBookmarkUpdate(input: {
      attributes: $attributes,
      id: $id
    }) {
      errors
      success
    }
  }
`

export default mutationUpdate
