import { createContext } from 'react'

import type { ContextData } from './Types'

export const DEFAULT_DATA: ContextData = {
  toast: (_options) => '',
}

// Context instance
const ToastContext = createContext(DEFAULT_DATA)
ToastContext.displayName = 'ToastContext'

// export provider/consumer
export const Provider = ToastContext.Provider
export const Consumer = ToastContext.Consumer

// Export the context
export default ToastContext
