import { isFunction, filter } from 'lodash'
import includes from 'lodash/includes'
import { useEffect, useRef, useState } from 'react'
import { GraphQLErrorExtensions } from 'graphql'
import { ApolloError, isApolloError } from '@apollo/client'
import { useToast } from 'services/Toast'
import logError from '../utils/logError'

export interface DefaultError extends Error {
  response?: {
    status?: number | string
  }
}

export type UseQueryError = ApolloError | DefaultError | undefined | null

interface UseQueryErrorOptions {
  /**
   * Will be called with the selected error.
   */
  callback?: (error: UseQueryError) => void;

  /**
   * Won't raise 404 errors
   */
  skipNotFound?: boolean;

  /**
   * List of error codes that should be ignored
   */
  skipCodes?: string[];
}

export type ErrorData = (GraphQLErrorExtensions | {
  [key: string]: unknown;
} | undefined)[] | null

/**
 * Handle apollo query/mutation error
 */
function useQueryError(
  error: UseQueryError,
  options: UseQueryErrorOptions = {}
) {
  const toast = useToast()
  const mounted = useRef(false)
  const [errorData, setErrorData] = useState<ErrorData>(null)

  //
  // Options cannot be used directly since it would cause
  // too many render cycles. Instead, we don't react on
  // options changes direct.
  const opts = useRef(options)

  //
  // Set options
  useEffect(() => {
    mounted.current = true
    opts.current = options

    return function cleanUp() {
      mounted.current = false
    }
  }, [options])

  //
  // Handle error
  useEffect(() => {
    if (!error) {
      setErrorData(null)
      return
    }

    if (!isApolloError(error)) {
      if ((error?.response?.status === 404 ||
           error?.response?.status === '404') &&
           opts.current?.skipNotFound === true) {
        return
      }

      if (includes(opts.current.skipCodes || [], `${error?.response?.status}`)) {
        return
      }
    } else {
      const data = error?.graphQLErrors?.map((e) => e?.extensions)

      if (mounted?.current) setErrorData(data)

      const notFoundErrors = filter(data, (e) => e?.code === 'not_found')

      if (notFoundErrors?.length > 0
          && opts.current?.skipNotFound === true) {
        return
      }

      const skippedErrors = filter(data, (e) => includes(opts.current?.skipCodes || [], e?.code))

      if (skippedErrors?.length > 0) {
        return
      }
    }

    logError(error)

    toast({
      content: `Database Error: ${error.message}`,
      type: 'error',
    })

    if (isFunction(opts.current?.callback)) opts.current.callback(error)
  }, [error, opts, toast])

  return {
    error,
    errorData,
  }
}

export default useQueryError
