import { isPresent } from 'lib/utils'
import Config from '~/util/Config'

export const APP_STORE_IOS_ID = '1509942885'
export const APP_STORE_ANDROID_ID = 'com.vayapin.app'
export const STORE_LINKS = {
  'ios': `https://apps.apple.com/de/app/vayapin/id${APP_STORE_IOS_ID}`,
  'android': `https://play.google.com/store/apps/details?id=${APP_STORE_ANDROID_ID}`,
}

const PROTOCOL = Config.NEXT_PUBLIC_APP_PROTOCOL || ''

function openApp(os: string | null, id: string | undefined) {
  if (typeof window === 'undefined') return
  if (!isPresent(id)) return

  let url = `${PROTOCOL}://${id}`

  window.location.replace(url)

  setTimeout(() => {
    url = os === 'ios' ? STORE_LINKS.ios : STORE_LINKS.android
    window.open(url, '_blank')?.focus()
  }, 1000)
}

export default openApp
