import { useEffect } from 'react'

import useLazyCurrentPosition from './useLazyCurrentPosition'

/**
 * Wrapper arround `navigator.geolocation.getCurrentPosition`. It
 * will return:
 * - {@link GeolocationPosition} if geolocation was successfull
 * - `false` if not
 * - `undefined` if not done yet
 */
function useCurrentPosition(skip = false, options?: PositionOptions) {
  const { currentPosition, locate } = useLazyCurrentPosition(options)

  //
  // Initial data load
  useEffect(() => {
    if (!skip) void locate()
  }, [locate, skip])

  //
  // Return
  return currentPosition
}

export default useCurrentPosition
