import isPresent from './isPresent'

/**
 * Removes
 */
function removeTrailingSlashes(
  value: unknown,
  maxAmount = 5
): string {
  if (!isPresent(value)) return ''

  // It is important to not use /+ as regex to
  // prevent DoS attacks by passing in a string with a
  // large number of slashes.
  return value.replace(
    new RegExp(`/{0,${maxAmount}}$`),
    ''
  )
}

export default removeTrailingSlashes
