import { useContext } from 'react'

import Context from './Context'

import type { ContextData } from './Types'

function useToast(): ContextData['toast'] {
  const { toast } = useContext(Context)

  return toast
}

export default useToast
